import faviconCardimate from 'assets/images/favicon-cardimate.png'
import faviconDancebit from 'assets/images/favicon-dancebit.png'
import faviconFamio from 'assets/images/favicon-famio.png'
import faviconFitme from 'assets/images/favicon-fitme.webp'
import faviconLuvly from 'assets/images/favicon-luvly.png'
import faviconNove8 from 'assets/images/favicon-nove8.png'
import faviconNutrimate from 'assets/images/favicon-nutrimate.png'
import faviconUpluv from 'assets/images/favicon-upluv.png'
import faviconWoofz from 'assets/images/favicon-woofz.png'

import { AppName } from 'root-constants'

export const META_INFO = {
  [AppName.LUVLY]: { favicon: faviconLuvly, title: 'Luvly' },
  [AppName.DANCEBIT]: { favicon: faviconDancebit, title: 'Dancebit' },
  [AppName.NUTRIMATE]: { favicon: faviconNutrimate, title: 'NutriMate' },
  [AppName.FAMIO]: { favicon: faviconFamio, title: 'Famio' },
  [AppName.UPLUV]: { favicon: faviconUpluv, title: 'UpLuv' },
  [AppName.WOOFZ]: { favicon: faviconWoofz, title: 'Woofz' },
  [AppName.MEOWZ]: { favicon: faviconNove8, title: 'Nove8' },
  [AppName.CARDIMATE]: { favicon: faviconCardimate, title: 'Cardimate' },
  [AppName.FITME]: { favicon: faviconFitme, title: 'Fitme' },
}
