import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import { getAuth, signOut } from 'firebase/auth'

import { DownloadBanner } from 'components/DownloadBanner'
import { FooterContainer } from 'components/FooterContainer'
import { Header } from 'components/Header'
import { Modal } from 'components/Modal'
import { Spinner } from 'components/Spinner'

import { setErrorAction } from 'root-redux/actions/common'
import {
  UNSUBSCRIBE,
  getCustomerInfoAction,
  getUserSubscriptionsInfoAction,
  setAuthTokenAction,
  unsubscribeUserByTokenAction,
} from 'root-redux/actions/user'
import {
  selectActionList,
  selectAppName,
  selectSource,
} from 'root-redux/selects/common'
import {
  selectAuthToken,
  selectUUID,
  selectUserContactEmail,
  selectUserName,
  selectUserSubscriptionInfo,
} from 'root-redux/selects/user'
import { TAppDispatch } from 'root-redux/store/store'

import { getLoginMethodFromLocalStorage } from 'helpers/getLoginMethodFromLocalStorage'
import { getMobileOperatingSystem } from 'helpers/getMobileOperatingSystem'
import { setZendeskChat } from 'helpers/setZendeskChat'

import { setIsModalShownAction } from 'modules/contactForm/redux'
import { ContactSupport } from 'modules/unsubscribe/components/ContactSupport'
import { DetailsTab } from 'modules/unsubscribe/components/DetailsTabs'
import { TABS } from 'modules/unsubscribe/components/DetailsTabs/DetailsTab'

import { eventLogger } from 'services/eventLogger.service'

import { goTo } from 'browser-history'
import { SubscriptionContainer, SubscriptionTitle } from 'common-styles'
import {
  PlatformOS,
  SubscriptionInterval,
  SubscriptionStatus,
  TRIAL_BILLING_CYCLE_KEYS,
  TRIAL_DAILY_PERIOD,
  TRIAL_MONTHLY_PERIOD,
  TrialBillingCycle,
  ZENDESK_LIVECHAT_APPS,
} from 'root-constants'

import { StyledSubscriptionDetails as S } from './SubscriptionDetails.styles'

const CANCELLED_STATUSES = [
  SubscriptionStatus.CANCELLED,
  SubscriptionStatus.NOT_ACTIVE,
  SubscriptionStatus.DEACTIVATED,
]

export const SubscriptionDetails: React.FC = () => {
  const { search } = useLocation()
  const { t } = useTranslation()
  const dispatch: TAppDispatch = useDispatch()
  const appName = useSelector(selectAppName)
  const userSubscriptionInfo = useSelector(selectUserSubscriptionInfo)
  const fetchingActionsList = useSelector(selectActionList)
  const source = useSelector(selectSource)
  const email = useSelector(selectUserContactEmail)
  const userName = useSelector(selectUserName)
  const userId = useSelector(selectUUID)
  const authToken = useSelector(selectAuthToken)

  const [selectedTab, setSelectedTab] = useState(TABS.ACCOUNT)

  const isUnsubscribeInProgress = useMemo(
    () => fetchingActionsList.includes(UNSUBSCRIBE),
    [fetchingActionsList],
  )

  const hasOpenInAppLink = useMemo(() => {
    const OS = getMobileOperatingSystem()
    return OS !== PlatformOS.UNKNOWN
  }, [])

  const getBillingCycleKey = useCallback((daysCount: number) => {
    if (daysCount < TrialBillingCycle.SEVEN_DAYS) return TRIAL_DAILY_PERIOD

    return TRIAL_BILLING_CYCLE_KEYS[daysCount] || TRIAL_MONTHLY_PERIOD
  }, [])

  const isCancelled = useMemo(
    () =>
      userSubscriptionInfo &&
      CANCELLED_STATUSES.includes(userSubscriptionInfo.status),
    [userSubscriptionInfo],
  )

  const getAndSaveAuthToken = useCallback(() => {
    if (authToken) return

    const query = new URLSearchParams(search)
    const token = query.get('token')

    if (token) {
      dispatch(setAuthTokenAction(token))
      return
    }

    goTo('login')
  }, [authToken, dispatch, search])

  const signOutFirebase = useCallback(() => signOut(getAuth()), [])

  useEffect(() => {
    !authToken && getAndSaveAuthToken()
  }, [authToken, getAndSaveAuthToken])

  useEffect(() => {
    !userId && dispatch(getCustomerInfoAction({ hasEvent: false }))
  }, [dispatch, userId])

  useEffect(() => {
    !userSubscriptionInfo &&
      dispatch(getUserSubscriptionsInfoAction(signOutFirebase))
  }, [dispatch, signOutFirebase, userSubscriptionInfo])

  const hasZendeskChat = useMemo(
    () => ZENDESK_LIVECHAT_APPS.includes(appName),
    [appName],
  )

  useEffect(() => {
    if (hasZendeskChat && !window.zE) {
      setZendeskChat({
        appName,
        openOnInit: false,
      })
    }
  }, [appName, hasZendeskChat])

  useEffect(() => {
    if (!userSubscriptionInfo || !email) return

    eventLogger.logSubscriptionDetailsShow({
      userId,
      source,
      email,
      method: getLoginMethodFromLocalStorage(),
    })
  }, [email, source, userId, userSubscriptionInfo])

  const removeSubscription = useCallback(() => {
    dispatch(unsubscribeUserByTokenAction())
  }, [dispatch])

  const resetPassword = useCallback(() => {
    eventLogger.logForgotPassword(source)

    goTo({
      pathname: 'reset-password',
      search,
    })
  }, [search, source])

  const handleCloseModal = useCallback(() => {
    dispatch(setErrorAction(''))

    dispatch(getUserSubscriptionsInfoAction(signOutFirebase))

    dispatch(setIsModalShownAction(false))
  }, [dispatch, signOutFirebase])

  return !userSubscriptionInfo || !email || isUnsubscribeInProgress ? (
    <Spinner />
  ) : (
    <>
      {hasOpenInAppLink ? <DownloadBanner /> : <Header />}
      <S.Wrapper hasOpenInAppLink={hasOpenInAppLink}>
        <SubscriptionTitle>{t('subscription.title')}</SubscriptionTitle>
        <DetailsTab activeTab={selectedTab} handleChange={setSelectedTab} />
        <S.Container>
          {selectedTab === TABS.ACCOUNT && (
            <SubscriptionContainer>
              <S.CardTitle>{t('subscription.details')}</S.CardTitle>
              {userName && (
                <S.InfoRow>
                  <span>{t('subscription.name')}</span>
                  <S.InfoValue>{userName}</S.InfoValue>
                </S.InfoRow>
              )}
              <S.InfoRow>
                <span>{t('login.email')}</span>
                <S.InfoValue>{email}</S.InfoValue>
              </S.InfoRow>

              <S.ResetPassword onClick={resetPassword}>
                {t('login.reset')}
              </S.ResetPassword>
            </SubscriptionContainer>
          )}
          {selectedTab === TABS.SUBSCRIPTIONS &&
            (isCancelled ? (
              <SubscriptionContainer backgroundColor="#F2F3F5">
                <S.CardTitle>
                  {t('subscription.yourPlan', { context: appName })}
                </S.CardTitle>

                <S.UnsubscribeInfo>
                  <Trans
                    i18nKey="subscription.cancel.text"
                    values={{ context: appName }}
                  />
                </S.UnsubscribeInfo>
              </SubscriptionContainer>
            ) : (
              <SubscriptionContainer>
                <S.CardTitle>
                  {t('subscription.yourPlan', { context: appName })}
                </S.CardTitle>

                {userSubscriptionInfo.status === SubscriptionStatus.TRIAL && (
                  <>
                    <S.SubscriptionRow>
                      <span>{t('subscription.trialPeriod')}</span>
                      <S.InfoValue>
                        {t(
                          `subscription.trialBillingPeriods.${getBillingCycleKey(
                            userSubscriptionInfo.trialDaysCount,
                          )}`,
                          {
                            count: userSubscriptionInfo.trialDaysCount,
                          },
                        )}
                      </S.InfoValue>
                    </S.SubscriptionRow>
                    <S.SubscriptionRow>
                      <span>{t('subscription.trialPrice')}</span>
                      <S.InfoValue>
                        {t('subscription.priceAmount', {
                          currency: userSubscriptionInfo.currency,
                          amount: userSubscriptionInfo.trialPrice,
                        })}
                      </S.InfoValue>
                    </S.SubscriptionRow>
                    <S.Separator />
                  </>
                )}

                <S.SubscriptionRow>
                  <span>{t('subscription.billingPeriod')}</span>
                  <S.InfoValue>
                    {userSubscriptionInfo.interval ===
                    SubscriptionInterval.MONTH
                      ? t('subscription.billingPeriods.month', {
                          count: userSubscriptionInfo.intervalCount,
                        })
                      : t(
                          `subscription.trialBillingPeriods.${getBillingCycleKey(
                            userSubscriptionInfo.intervalCount,
                          )}`,
                          {
                            count: userSubscriptionInfo.intervalCount,
                          },
                        )}
                  </S.InfoValue>
                </S.SubscriptionRow>
                <S.SubscriptionRow>
                  <span>{t('subscription.nextChargeDate')}</span>
                  <S.InfoValue>{userSubscriptionInfo.endDate}</S.InfoValue>
                </S.SubscriptionRow>
                <S.SubscriptionRow>
                  <span>{t('subscription.subscriptionPrice')}</span>
                  <S.InfoValue>
                    {t('subscription.priceAmount', {
                      currency: userSubscriptionInfo.currency,
                      amount: userSubscriptionInfo.price,
                    })}
                  </S.InfoValue>
                </S.SubscriptionRow>

                <S.ResetPassword onClick={removeSubscription}>
                  {t('subscription.turnOffRenewal')}
                </S.ResetPassword>
              </SubscriptionContainer>
            ))}
          <Modal onClose={handleCloseModal} />
        </S.Container>
        <FooterContainer>
          <ContactSupport />
        </FooterContainer>
      </S.Wrapper>
    </>
  )
}
