import React, {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'

import { Attachments } from 'components/Attachments'
import { Button } from 'components/Button'
import { Container } from 'components/Container'
import { DomainsContainer } from 'components/DomainsContainer'
import { InputHookForm } from 'components/InputHookForm'
import { Modal } from 'components/Modal'
import { Select } from 'components/Select'
import { Spinner } from 'components/Spinner'
import { Textarea } from 'components/Textarea'

import { resetErrorAction } from 'root-redux/actions/common'
import {
  selectActionList,
  selectAppName,
  selectError,
} from 'root-redux/selects/common'
import { TAppDispatch } from 'root-redux/store/store'

import { useClickOutside } from 'hooks/useClickOutside'

import { setZendeskChat } from 'helpers/setZendeskChat'

import {
  ATTACHMENTS_NUMBER_ERROR,
  ATTACHMENTS_SIZE_ERROR,
  EMAIL_DOMAINS,
  EMAIL_DOMAIN_REGEXP,
  EMAIL_USERNAME_REGEXP,
  EMAIL_VALIDATION,
  MAX_FILE_NUMBER,
  MAX_FILE_SIZE,
  NAME_VALIDATION,
  REQUEST_TOPICS,
} from 'modules/contactForm/constants'
import { checkFileProperties } from 'modules/contactForm/helpers'
import {
  SEND_FORM,
  UPLOAD_FILES,
  removeAllFilesAction,
  removeFileAction,
  selectIsCaptchaValid,
  sendCustomFormAction,
  setIsCaptchaValidAction,
  setIsModalShownAction,
  uploadFilesAction,
  verifyGoogleCaptchaAction,
} from 'modules/contactForm/redux'

import { APP_BG_COLORS, ZENDESK_LIVECHAT_APPS } from 'root-constants'

import { StyledContactForm as S } from './ContactForm.styles'

const CAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY

export const ContactForm: React.FC = () => {
  const dispatch: TAppDispatch = useDispatch()
  const captchaRef = useRef<ReCAPTCHA>(null)
  const autocompleteRef = useRef<HTMLDivElement>(null)
  const appName = useSelector(selectAppName)
  const error = useSelector(selectError)
  const fetchingActionsList = useSelector(selectActionList)
  const isCaptchaValid = useSelector(selectIsCaptchaValid)

  const [areEmailTipsVisible, setAreEmailTipsVisible] = useState(false)
  const [email, setEmail] = useState('')
  const [userFiles, setUserFiles] = useState<File[]>([])
  const [attachmentsError, setAttachmentsError] = useState('')
  const [isZendeskLoaded, setIsZendeskLoaded] = useState(false)

  const {
    register,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      name: '',
      email: '',
      topic: '',
      request: '',
    },
  })
  const deferredEmail = useDeferredValue(email)

  useClickOutside({
    ref: autocompleteRef,
    callback: () => setAreEmailTipsVisible(false),
  })

  const domainsList = useMemo(() => {
    const [, emailDomain] = EMAIL_DOMAIN_REGEXP.exec(deferredEmail) || []
    const [userName] = EMAIL_USERNAME_REGEXP.exec(deferredEmail) || []
    return EMAIL_DOMAINS.filter((domain) => domain.includes(emailDomain)).map(
      (filteredDomain) => `${userName}${filteredDomain}`,
    )
  }, [deferredEmail])

  const isFormSending = useMemo(
    () => fetchingActionsList.includes(SEND_FORM),
    [fetchingActionsList],
  )

  const areFilesUploading = useMemo(
    () => fetchingActionsList.includes(UPLOAD_FILES),
    [fetchingActionsList],
  )

  const hasZendeskChat = useMemo(
    () => ZENDESK_LIVECHAT_APPS.includes(appName),
    [appName],
  )

  useEffect(() => {
    if (hasZendeskChat && !window.zE) {
      setZendeskChat({
        appName,
        setIsZendeskLoaded,
      })
    }
  }, [appName, hasZendeskChat])

  const onSubmit = useCallback(
    (data) => {
      dispatch(sendCustomFormAction(data))
    },
    [dispatch],
  )

  const handleFileInputChange = useCallback(
    ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
      if (!files) return
      const availableFilesNumber = MAX_FILE_NUMBER - userFiles.length
      const uploadedFiles = Array.from(files)

      if (uploadedFiles.length > availableFilesNumber) {
        setAttachmentsError(ATTACHMENTS_NUMBER_ERROR)
      }

      if (uploadedFiles.some(({ size }) => size >= MAX_FILE_SIZE)) {
        setAttachmentsError(ATTACHMENTS_SIZE_ERROR)
      }

      const filteredFiles = Array.from(files)
        .filter(
          (file) =>
            !userFiles.find(
              ({ name, lastModified }) =>
                file.name === name && file.lastModified === lastModified,
            ) && checkFileProperties(file),
        )
        .slice(0, availableFilesNumber)

      if (!filteredFiles.length) return

      dispatch(uploadFilesAction(filteredFiles))
      setUserFiles((prevState) => [...prevState, ...filteredFiles])
    },
    [dispatch, userFiles],
  )

  const handleDeleteFile = useCallback(
    (fileId: string) => {
      const remainingFiles = userFiles.filter(
        ({ name, lastModified }) => `${name}_${lastModified}` !== fileId,
      )
      setUserFiles(remainingFiles)
      setAttachmentsError('')
      dispatch(removeFileAction(fileId))
    },
    [dispatch, userFiles],
  )

  const handleChange = useCallback(({ target: { value } }) => {
    setEmail(value.toLowerCase().trim())
    setAreEmailTipsVisible(true)
  }, [])

  const handlePrefilledEmail = useCallback(
    (domain) => {
      return () => {
        setValue('email', domain, {
          shouldValidate: true,
        })
        setEmail(domain)
        setAreEmailTipsVisible(false)
      }
    },
    [setValue],
  )

  const handleGoogleCaptcha = useCallback(
    (captchaToken: string | null) => {
      dispatch(verifyGoogleCaptchaAction(captchaToken))
    },
    [dispatch],
  )

  const handleCloseModal = useCallback(() => {
    captchaRef.current && captchaRef.current.reset()
    dispatch(setIsModalShownAction(false))
    dispatch(setIsCaptchaValidAction(false))
    dispatch(removeAllFilesAction())
    setUserFiles([])

    if (window?.webkit?.messageHandlers?.jsHandler) {
      window.webkit.messageHandlers.jsHandler.postMessage('Popup was closed')
    }

    if (window?.JSBridge?.postMessage) {
      window.JSBridge.postMessage('Popup was closed')
    }

    if (!error) {
      reset()
      return
    }
    dispatch(resetErrorAction())
  }, [dispatch, error, reset])

  if (hasZendeskChat) {
    return !isZendeskLoaded ? <Spinner /> : null
  }

  return (
    <S.Wrapper>
      <S.Header />
      {isFormSending && <Spinner />}
      <Container>
        <S.Title>Contact us</S.Title>
        <S.Form
          onSubmit={handleSubmit(onSubmit)}
          backgroundColor={APP_BG_COLORS[appName]}
        >
          <InputHookForm
            register={register('name', {
              required: 'Name is required',
              validate: { ...NAME_VALIDATION },
            })}
            spellCheck={false}
            labelName="Your name *"
            isValid={!errors.name}
            validationText={errors.name?.message as string}
            marginBottom={24}
          />
          <S.InputContainer>
            <InputHookForm
              register={register('email', {
                required: 'Email is required',
                validate: { ...EMAIL_VALIDATION },
                onChange: handleChange,
              })}
              labelName="Your email *"
              isValid={!errors.email}
              validationText={errors.email?.message as string}
              marginBottom={24}
            />
            {areEmailTipsVisible && (
              <DomainsContainer containerRef={autocompleteRef}>
                {domainsList.map((item) => (
                  <button
                    type="button"
                    key={item}
                    value={item}
                    onClick={handlePrefilledEmail(item)}
                  >
                    {item}
                  </button>
                ))}
              </DomainsContainer>
            )}
          </S.InputContainer>
          <Select
            register={register('topic', {
              required: 'Topic is required',
            })}
            options={REQUEST_TOPICS}
            labelName="Select topic *"
            isValid={!errors.topic}
            validationText={errors.topic?.message as string}
          />
          <Textarea
            register={register('request', {
              required: 'Request is required',
            })}
            labelName="Specify your request *"
            isValid={!errors.request}
            validationText={errors.request?.message as string}
            spellCheck={false}
            placeholder="Add subscription information, the device you’re using, the errors you see, etc."
          />
          <Attachments
            onChange={handleFileInputChange}
            files={userFiles}
            setAttachmentsError={setAttachmentsError}
            onDeleteFile={handleDeleteFile}
            labelName="Attachments"
            validationText={attachmentsError}
          />

          {isValid && CAPTCHA_SITE_KEY && (
            <S.CaptchaContainer>
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={CAPTCHA_SITE_KEY}
                onChange={handleGoogleCaptcha}
              />
            </S.CaptchaContainer>
          )}

          <Button
            type="submit"
            disabled={
              !isValid || isFormSending || areFilesUploading || !isCaptchaValid
            }
          >
            Submit
          </Button>
        </S.Form>
      </Container>
      <Modal userEmail={getValues('email')} onClose={handleCloseModal} />
    </S.Wrapper>
  )
}
