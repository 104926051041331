import dayjs from 'dayjs'
import 'dayjs/locale/de'
import 'dayjs/locale/es'
import 'dayjs/locale/fr'
import 'dayjs/locale/it'

const lang = navigator.language || 'en'

export const getDateFromTimeStamp = (timeStamp: number): string =>
  timeStamp ? dayjs.unix(timeStamp).locale(lang).format('DD MMM YYYY') : ''
