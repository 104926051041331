import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Header } from 'components/Header'

import { selectAppName } from 'root-redux/selects/common'

import { useInitFirebase } from 'hooks/useInitFirebase'

import { setZendeskChat } from 'helpers/setZendeskChat'

import { ResetPassword } from 'modules/unsubscribeResetPassword/components/ResetPassword'

import { ZENDESK_LIVECHAT_APPS } from 'root-constants'

import { StyledUnsubscribeResetPassword as S } from './UnsubscribeResetPassword.styles'

export const UnsubscribeResetPassword: React.FC = () => {
  const appName = useSelector(selectAppName)

  useInitFirebase()

  const hasZendeskChat = useMemo(
    () => ZENDESK_LIVECHAT_APPS.includes(appName),
    [appName],
  )

  useEffect(() => {
    if (hasZendeskChat && !window.zE) {
      setZendeskChat({
        appName,
        openOnInit: false,
      })
    }
  }, [appName, hasZendeskChat])

  return (
    <S.Wrapper>
      <Header />
      <ResetPassword />
    </S.Wrapper>
  )
}
